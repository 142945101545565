import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigation } from './components/navigation';
import { Home, Service, Feedback } from './pages';
import { DataProvider } from './hook/DataContext';
import { Toaster } from 'react-hot-toast';

import './App.css';

export default function App() {
    return (
        <DataProvider>
            <Router>
                <Navigation />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    {/* <Route path="/about" element={<About />} /> */}
                    <Route path="/service" element={<Service />} />
                    <Route path="/feedback" element={<Feedback />} />
                </Routes>
            </Router>
            <Toaster />
        </DataProvider>
    );
}
