import React from 'react';
import { Services, Testimonials } from '../components';
import { useData } from '../hook/DataContext';
export default function Service() {
    const data = useData().data;
    return (
        <div className="page-container">
            <Services data={data.Services} />
            <Testimonials data={data.Testimonials} />
        </div>
    );
}
