import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useData, useDataUpdate } from '../hook/DataContext';
import toast from 'react-hot-toast';

export const Navigation = () => {
    const data = useData();
    const toggle = useDataUpdate();
    useEffect(() => {
        toast(
            data.english
                ? 'This Website is still under construction!'
                : '该网站还在建设中，尽情期待！',
            {
                icon: '👏',
            }
        );
    }, [data]);
    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1">
                        {' '}
                        <span className="sr-only">Toggle navigation</span>{' '}
                        <span className="icon-bar"></span>{' '}
                        <span className="icon-bar"></span>{' '}
                        <span className="icon-bar"></span>{' '}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">
                        The One Wellness Workshop
                    </a>{' '}
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <Link to="/" className="page-scroll">
                                {data.english ? 'Home' : '首页'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/service" className="page-scroll">
                                {data.english ? 'Services' : '服务'}
                            </Link>
                        </li>
                        <li>
                            <Link to="/feedback" className="page-scroll">
                                {data.english ? 'Feedback' : '反馈'}
                            </Link>
                        </li>
                        <li>
                            <button
                                onClick={toggle}
                                id="language_btn"
                                className="btn btn-light">
                                {data.english ? '中文' : 'English'}
                            </button>
                        </li>
                        {/*<li>
                            <a href="#team" className="page-scroll">
                                Team
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className="page-scroll">
                                Contact
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
};
