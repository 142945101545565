import React from 'react';
import { Contact } from '../components';
import { useData } from '../hook/DataContext';

export default function Feedback() {
    const data = useData().data;
    return (
        <div className="page-container">
            <Contact data={data.Contact} />
        </div>
    );
}
