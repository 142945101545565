import React, { createContext, useContext, useState } from 'react';

import EnglishData from '../data/english.json';
import ChineseData from '../data/chinese.json';

const DataContext = createContext();
const DataUpdateContext = createContext();

export function useData() {
    return useContext(DataContext);
}

export function useDataUpdate() {
    return useContext(DataUpdateContext);
}

export function DataProvider({ children }) {
    const [data, setData] = useState({
        english: true,
        data: EnglishData,
    });
    function toggleLanguage() {
        setData((prev) => {
            return {
                english: !prev.english,
                data: prev.english ? ChineseData : EnglishData,
            };
        });
    }

    return (
        <DataContext.Provider value={data}>
            <DataUpdateContext.Provider value={toggleLanguage}>
                {children}
            </DataUpdateContext.Provider>
        </DataContext.Provider>
    );
}
