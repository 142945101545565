import React from 'react';
import { Header, Features, About, Team } from '../components';
import { useData } from '../hook/DataContext';

export default function Home() {
    const data = useData().data;
    return (
        <>
            <Header data={data.Header} />
            <Features data={data.Features} />
            <About data={data.About} />
            <Team data={data.Team} />
        </>
    );
}
